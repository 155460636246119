import React from "react";
import Layout from "../components/layout";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import WaveBottom from "../components/Wave/WaveBottom";
import SEO from "../components/seo";
import Sugar from "sugar";

const Post = ({
  data,
  data: {
    markdownRemark: {
      excerpt,
      frontmatter: { title, date, featuredImage },
      html,
    },
  },
}) => {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={title}
      />
      <div className="bg-white px-4 pt-2 pb-16 text-black">
        <div className="bg-gray-200 p-3 rounded font-sans container w-full mx-auto mb-4">
          <ol className="list-reset flex text-gray-700">
            <li>
              <Link
                to="/"
                className="font-bold text-gray-800 hover:text-gray-700 transition duration-100"
              >
                Home
              </Link>
            </li>
            <li>
              <span className="mx-2">/</span>
            </li>

            <li>
              <Link
                to="/posts"
                className="font-bold text-gray-800 hover:text-gray-700 transition duration-100"
              >
                Posts
              </Link>
            </li>

            <li>
              <span className="mx-2">/</span>
            </li>
            <li>{title}</li>
          </ol>
        </div>

        <div className="container mx-auto px-4">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            {title}
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="my-5 text-sm text-center">
            {Sugar.Date.medium(Sugar.Date.create(date))}
          </div>
          <div className="max-w-3xl mx-auto mb-8">
            <Img
              fluid={featuredImage.childImageSharp.fluid}
              alt={title}
              className="w-full "
            />
          </div>
          <div
            className="markdown"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>
      </div>

      <WaveBottom color="#ffffff" />
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query PostTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date
        featuredImage {
          relativePath
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
      }
      html
    }
  }
`;
